import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LottieModule} from 'ngx-lottie';
import {NgxUiLoaderModule} from 'ngx-ui-loader';



@NgModule({
  imports: [
    CommonModule,
    LottieModule,
    NgxUiLoaderModule
  ]
})
export class SharedModule { }
