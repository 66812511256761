import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private LoaderStateSubject = new BehaviorSubject({show: false, loaderId: ''});
  LoaderState = this.LoaderStateSubject.asObservable();

  constructor() { }

  showLoader(): void{
    this.LoaderStateSubject.next({show: true, loaderId: ''});
  }

  hideLoader(): void{
    this.LoaderStateSubject.next({show: false, loaderId: ''});
    console.log(this.LoaderStateSubject);
  }

}
