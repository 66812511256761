<p-menubar [model]="items" [styleClass]="'heroheader'">
  <ng-template pTemplate="start">
    <img src="https://tfsmsgate.blob.core.windows.net/merchantlogos/samsung-logo-white.svg"
      class="menubar-logo p-mr-4" />
  </ng-template>
  <ng-template pTemplate="end">
    <div class="p-d-flex p-ai-center">
      <p class="p-m-0 user-name">{{app_user?.firstName + " " + app_user?.lastName | titlecase}}</p>
      <!-- <p-button label="Log Out" styleClass="p-button-danger p-button-rounded" class="p-ml-4" (click)="signOut()">
      </p-button> -->
      <button pButton type="button" icon="pi pi-sign-out" iconPos="left" (click)="signOut()" class="p-ml-4"></button>
    </div>
  </ng-template>
</p-menubar>

<div [formGroup]=" smsConfigForm">
  <p-dialog header="Configuration" [(visible)]="configModal" [style]="{width: '50vw'}" [modal]="true" [closable]="true"
    [closeOnEscape]="true">
    <div class="p-fluid">
      <div class="p-field">
        <label for="sms_template">SMS Template</label>
        <textarea pInputTextarea [rows]="3" formControlName="sms_template" id="sms_template"
          name="sms_template"></textarea>
        <small id="sms-template-pattern-help" *ngIf="smsConfigForm.controls['sms_template'].errors?.pattern"
          class="p-error">
          Please make sure to include these placeholders in your SMS template: @name.
        </small>
      </div>
      <div class="p-field">
        <label for="notif_age">Notification Age</label>
        <p-inputNumber [minFractionDigits]="0" formControlName="notif_age" name="notif_age" id="notif_age"
          suffix=" days"></p-inputNumber>
      </div>
      <div class="p-field">
        <label for="archive_age">Archive Age</label>
        <p-inputNumber [minFractionDigits]="0" formControlName="archive_age" name="archive_age" id="archive_age"
          suffix=" days"></p-inputNumber>
      </div>
    </div>
    <div class="p-d-flex p-jc-end">
      <p-button label="Save Changes" styleClass="p-button-rounded" [disabled]="smsConfigForm.invalid" type="submit"
        (click)="saveSmsConfig()">
      </p-button>
    </div>
  </p-dialog>
</div>

<p-toast key="responseToast" position="bottom-center"></p-toast>
