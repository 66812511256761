import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import {AppConfigService} from './services/app-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'samsung-alert';
  isIframe = false;




  constructor(private router: Router,
    private keycloakService: KeycloakService,
    private appconfigService: AppConfigService,
    private ngxPermissionsService: NgxPermissionsService) {

  }

  async ngOnInit() {
    // console.log('environment production', environment.production);
    this.isIframe = window !== window.parent && !window.opener;
    if (await this.keycloakService.isLoggedIn()) {
      const permissions = this.keycloakService.getUserRoles();
      // console.log(permissions);
      // const userProfile = await this.keycloakService.loadUserProfile();
      // console.log(userProfile);
      this.ngxPermissionsService.loadPermissions(permissions);
      // console.log(this.ngxPermissionsService.getPermissions());
    } else {
      await this.keycloakService.login();
    }

    // consolere.connect({
    //   server: this.appconfigService.settings.envVariables.APPSETTING_configService,
    //   channel: '94a1-937a-9d92', // required
    //   disableDefaultConsoleOutput: false, // optional, default: false
    // });

    // console.log(this.appconfigService.settings);

  }

}
