import { Component, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() loaderId: string = '';

  lottieOptions: any = {
    ticketconfirm: { path: '../../assets/loaders/ticketconfirm.json' },
    loading: { path: '../../assets/loaders/galaxy.json' }
  };

  constructor(
    private loaderService: LoaderService,
    private ngxLoader: NgxUiLoaderService) {
  }

  ngOnInit(): void {
    this.loaderService.LoaderState.subscribe(x => {
      if (x.show) {
        this.ngxLoader.start();
      } else {
        this.ngxLoader.stopAll();
      }
    });
  }

  startLoader(): void {
    this.ngxLoader.start();
  }

  stopLoader(): void {
    this.ngxLoader.stop();
  }


}
