import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NgxPermissionsGuard} from 'ngx-permissions';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'service-order'
  },
  {
    path: 'service-order',
    loadChildren: () => import('./service-order/service-order.module').then(m => m.ServiceOrderModule),
    // canActivate: [NgxPermissionsGuard],
    // data: {
    //   permissions: {
    //     only: ['samsung.administrator', 'samsung.audit.representative'],
    //     redirectTo: '/error/403'
    //   }
    // }
  },
  { path: 'error', loadChildren: () => import('./error/error.module').then(m => m.ErrorModule) },
  { path: 'log', loadChildren: () => import('./log/log.module').then(m => m.LogModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
