// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  signalRURI: 'https://qapps-api.timefree.ph/talk',
  qmsURI: 'https://tfqmsservicesmts-n.azurewebsites.net/',
  keyCloak_url: 'https://auth.timefree.ph/auth',
  blobSAS: '?sv=2019-10-10&st=2021-02-17T12%3A00%3A14Z&se=2044-12-01T12%3A00%3A00Z&sr=c&sp=racwl&sig=PW%2BEm5Y7mIvfb1WRaV3WuF7pNxDPZhBJzFOJBIkjfPQ%3D',
  blobURI: 'https://tfsmsgate.blob.core.windows.net/samsung-audit?sv=2019-10-10&st=2021-02-17T12%3A00%3A14Z&se=2044-12-01T12%3A00%3A00Z&sr=c&sp=racwl&sig=PW%2BEm5Y7mIvfb1WRaV3WuF7pNxDPZhBJzFOJBIkjfPQ%3D',
  blobContainer: 'samsung-audit',
  keyCloak_realm: 'Samsung',
  keyCloak_client: 'sms-manager',
  apiURI: 'https://samsung-proactive-api.azurewebsites.net/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
