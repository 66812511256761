<app-loader></app-loader>

<app-navbar></app-navbar>

<div class="main-body">
  <router-outlet></router-outlet>
</div>

<footer class="footer">
  <div class="container">
    <div class="content has-text-centered">
      <p>
        Powered by <a href="https://timefree.ph">TimeFree</a>
      </p>
    </div>
  </div>
</footer>