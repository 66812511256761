import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { KeycloakService } from 'keycloak-angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';
import { KeycloakUser, UserPrincipal } from '../model/model';
import * as _ from 'underscore';
import { Apollo, gql } from 'apollo-angular';
import { Mutation, Query } from '../../service/graphql';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

const SMS_CONFIG_QUERY = gql`{
  configPaged {
    items {
      archive_age
      notif_age
      guid
      id
      sms_template
    }
  }
}`;

const SMS_CONFIG_MUTATION = gql`
  mutation($input: configInput) {
    updateConfig(input: $input) {
      responseCode
      responseLabel
      responseMessage
    }
}`;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  items: MenuItem[] = [
    {
      label: 'Service Orders',
      routerLink: ['/service-order'],
      routerLinkActiveOptions: { exact: true }
    },
    {
      label: 'Configuration',
      command: () => {
        this.apollo.watchQuery<Query>({
          query: SMS_CONFIG_QUERY
        }).valueChanges.subscribe(({data, loading}) => {
          const config = data?.configPaged?.items as any;
          this.smsConfigForm.patchValue(config[0]);
          this.configModal = true
        });
      }
    },
    {
      label: 'Logs',
      items: [
        {
          label: 'SMS',
          routerLink: ['/log/sms']
        },
        {
          label: 'Batch',
          routerLink: ['/log/batch']
        }
      ]
    }
  ];

  logoutLabel: string = '';

  redirectURL: string = '/';

  logoutItems: MenuItem[] = [
    {
      label: 'Go to Staging',
      command: () => {
        this.redirectToStaging()
      }
    }
  ];

  // Should the collapsed nav show?
  showNav: boolean = false;
  isLoggedIn: boolean = false;
  // Is a user logged in?
  private stagingURL: string = '';
  app_user: KeycloakUser | undefined;

  configModal = false;

  smsConfigForm: FormGroup = this.formBuilder.group({
    sms_template: new FormControl(''),
    /* , Validators.pattern('^.*@name.*$') */
    archive_age: new FormControl(),
    notif_age: new FormControl(),
    guid: new FormControl(),
    id: new FormControl()
  });

  get authenticated(): boolean {
    // this.keycloakService.isLoggedIn().then(isLoggedIn => {
    //   return isLoggedIn;
    // });
    return false;
  }
  // The user
  get user(): UserPrincipal | null {
    return null;
  }

  constructor(private router: Router,
    private apollo: Apollo,
    private formBuilder: FormBuilder,
    private keycloakService: KeycloakService,
    private messageService: MessageService,
    private ngxPermissionsService: NgxPermissionsService) {

    keycloakService.loadUserProfile().then(r => {
      this.app_user = r;
    });

  }

  async ngOnInit() {
    this.isLoggedIn = await this.keycloakService.isLoggedIn();
    this.showNav = false;
    this.ngxPermissionsService.permissions$.subscribe(async x => {

    });

    //GET Redirect URL from token
    const token: any = this.keycloakService.getKeycloakInstance().tokenParsed;

    this.stagingURL = token.staging_url;

    console.log(token);
    if (token) {
      this.redirectURL = token.ref;
    }


  }

  async isAuthenticated(): Promise<boolean> {
    return await this.keycloakService.isLoggedIn();
  }


  async hasPermission(permission: string): Promise<boolean> {
    return await this.ngxPermissionsService.hasPermission(permission).then(x => {
      return x;
    });
  }

  // Used by the Bootstrap navbar-toggler button to hide/show
  // the nav in a collapsed state

  toggleNavBar(): void {
    this.showNav = !this.showNav;
  }

  async signOut() {
    // this.keycloakService.logout('https://samsung-s1.timefree.ph');
    // if (await this.ngxPermissionsService.hasPermission('samsung.audit.representative') && !await this.ngxPermissionsService.hasPermission('samsung.administrator')) {
    this.keycloakService.logout(this.redirectURL);
    // } else {
    // window.location.href = this.redirectURL;
    // }
  }

  signIn() {
    this.keycloakService.login();
  }

  private redirectToStaging() {
    window.location.href = this.stagingURL;
  }

  saveSmsConfig() {
    console.log('I am here');
    this.apollo.mutate<Mutation>({
      mutation: SMS_CONFIG_MUTATION,
      variables: {
        input: this.smsConfigForm.value
      }
    }).subscribe(x => {
      switch (x.data?.updateConfig?.responseCode) {
        case 202: {
          this.configModal = false;
          this.messageService.add({
            severity: 'success',
            key: 'responseToast',
            summary: x.data?.updateConfig?.responseLabel!
          })
        } break;
        default: {
          this.configModal = false;
          this.messageService.add({
            severity: 'error',
            key: 'responseToast',
            summary: x.data?.updateConfig?.responseLabel!
          })
        }
      }
    });
  }

}
