import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClientModule} from '@angular/common/http';
import { GraphQLModule } from './graphql.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MenubarModule} from 'primeng/menubar';
import {TabViewModule} from 'primeng/tabview';
import {NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderRouterModule} from 'ngx-ui-loader';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
import {SharedModule} from './shared/shared.module';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {environment} from '../environments/environment';
import {NgxPermissionsModule} from 'ngx-permissions';
import {NavbarComponent} from './navbar/navbar.component';
import {LoaderComponent} from './shared/loader/loader.component';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {InputTextModule} from 'primeng/inputtext';
import {ReactiveFormsModule} from '@angular/forms';
import {InputMaskModule} from 'primeng/inputmask';
import {InputNumberModule} from 'primeng/inputnumber';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {AppConfigService} from './services/app-config.service';


const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function playerFactory(): any {
  return player;
}

export function init_app(appConfigService: AppConfigService) {
  return () => appConfigService.load();
}

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keyCloak_url,
        realm: environment.keyCloak_realm,
        clientId: environment.keyCloak_client
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
      }
      // initOptions: {
      //   onLoad: 'login-required',
      //   checkLoginIframe: false
      // }
    });
}

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    NavbarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    GraphQLModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    NgxPermissionsModule.forRoot(),
    MenubarModule,
    TabViewModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule.forRoot(
      {
        showForeground: true
      }
    ),
    LottieModule.forRoot({player: playerFactory}),
    SharedModule,
    ButtonModule,
    DialogModule,
    InputTextModule,
    ReactiveFormsModule,
    InputMaskModule,
    InputNumberModule,
    ToastModule,
    InputTextareaModule,
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppConfigService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AppConfigService]
    },
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
